import React from "react";
import styled from "styled-components";
import CartItem from "./cartItem";

const cartItemsList = ({ itemsList }) => {
  return (
    <ItemsContainer>
      {itemsList.map((item) => {
        return <CartItem item={item} />;
      })}
    </ItemsContainer>
  );
};

export default cartItemsList;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
