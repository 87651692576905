import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const AccessoriesPicker = ({ accessories, allAccessories, setAccessories }) => {
  const toggleAccessory = (accessoryItem) => {
    let isFound = false;
    accessories.map((accessory) => {
      if (accessory.id === accessoryItem.id) {
        setAccessories((prev) => prev.filter((e) => e.id != accessoryItem.id));
        isFound = true;
      }
    });

    if (!isFound) {
      setAccessories((prev) => [...prev, accessoryItem]);
    }
  };

  return (
    <Picker>
      <p className="title">Add Edible Accessories</p>
      <motion.div
        className="toppings-selector"
        initial={{ y: 200, opacity: 0, scale: 0.8 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        transition={{ duration: 0.7 }}
      >
        {allAccessories.map((accessoryItem) => {
          return (
            <div
              className="topping"
              onClick={() => toggleAccessory(accessoryItem)}
            >
              <p className="topping-name">{accessoryItem.name}</p>
              <p className="topping-price">{`Rs ${accessoryItem.price}`}</p>
            </div>
          );
        })}
      </motion.div>
    </Picker>
  );
};

export default AccessoriesPicker;

const Picker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .toppings-selector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 10px;
  }

  .title {
    font-size: 20px;
    font-weight: 900;
    text-align: center;
  }
  .topping {
    display: flex;
    width: 40vw;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
    border: 1px solid;
    cursor: pointer;
    border-radius: 7px;
  }
  .topping-price {
    margin: 0;
  }
  .topping-name {
    margin: 0;
  }

  .selected {
    border: 2px solid black;
    font-weight: 900;
    box-shadow: 5px 5px 20px #a18d87;
    background-color: #a3705f;
    color: #f2e6e2;
  }
`;
