import React from "react";
import styled from "styled-components";
import SingleProductMini from "./productMiniView";

const ProductsList = ({ products }) => {
  return (
    <Products>
      {products.map((product) => {
        return <SingleProductMini product={product} />;
      })}
    </Products>
  );
};

export default ProductsList;

const Products = styled.div`
  display: grid;
  width: 90vw;
  margin: 0 auto;
  /* grid-template-columns: 1fr 1fr; */
  gap: 30px;
`;
