import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
const ToppingsPicker = ({ toppings, allToppings, setToppings }) => {
  const toggleTopping = (toppingItem) => {
    let isFound = false;
    toppings.map((topping) => {
      if (topping.id === toppingItem.id) {
        setToppings((prev) => prev.filter((e) => e.id != toppingItem.id));
        isFound = true;
      }
    });

    if (!isFound) {
      setToppings((prev) => [...prev, toppingItem]);
    }
  };

  return (
    <Picker>
      <p className="title">Add Toppings</p>
      <motion.div
        className="toppings-selector"
        initial={{ y: 200, opacity: 0, scale: 0.8 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        transition={{ duration: 0.7 }}
      >
        {allToppings.map((toppingItem) => {
          return (
            <div className="topping" onClick={() => toggleTopping(toppingItem)}>
              <p className="topping-name">{toppingItem.name}</p>
              <p className="topping-price">{`Rs ${toppingItem.price}`}</p>
            </div>
          );
        })}
      </motion.div>
    </Picker>
  );
};

export default ToppingsPicker;

const Picker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .toppings-selector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 10px;
  }

  .title {
    font-size: 20px;
    font-weight: 900;
    text-align: center;
  }
  .topping {
    display: flex;
    width: 40vw;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
    border: 1px solid;
    cursor: pointer;
    border-radius: 7px;
  }
  .topping-price {
    margin: 0;
  }
  .topping-name {
    margin: 0;
  }

  .selected {
    border: 2px solid black;
    font-weight: 900;
    box-shadow: 5px 5px 20px #a18d87;
    background-color: #a3705f;
    color: #f2e6e2;
  }
`;
