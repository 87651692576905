import React, { useState } from "react";
import styled from "styled-components";
import ProductCard from "./components/productCard";
import Reviews from "./components/reviews";
import Data from "../../../_data";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const SingleProduct = () => {
  const [isDescOpen, setIsDescOpen] = useState(false);
  const [isDeliveryOpen, setIsDeliveryOpen] = useState(false);
  const [isReviewsOpen, setIsReviewsOpen] = useState(false);
  const [isMakingOpen, setIsMakingOpen] = useState(false);

  return (
    <Product>
      <ProductCard product={Data.singleProduct} />
      <div className="seperator"></div>
      <div className="tabs">
        <div
          className="tab"
          onClick={() => {
            setIsDescOpen((prev) => !prev);
          }}
        >
          <p className="tab-name">Description</p>
          <p className="tab-switch-btn">
            {" "}
            {isDescOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </p>
        </div>
        {isDescOpen && "Description"}

        <div
          className="tab"
          onClick={() => {
            setIsDeliveryOpen((prev) => !prev);
          }}
        >
          <p className="tab-name">Delivery</p>
          <p className="tab-switch-btn">
            {" "}
            {isDeliveryOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </p>
        </div>
        {isDeliveryOpen && "Delivery"}

        <div
          className="tab"
          onClick={() => {
            setIsReviewsOpen((prev) => !prev);
          }}
        >
          <p className="tab-name">Reviews</p>
          <p className="tab-switch-btn">
            {" "}
            {isReviewsOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </p>
        </div>
        {isReviewsOpen && <Reviews reviews={Data.reviews} />}
      </div>
    </Product>
  );
};

export default SingleProduct;

const Product = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  gap: 20px;
  .seperator {
    border-bottom: 1px solid;
    width: 90vw;
    height: 2px;
    opacity: 30%;
  }
  .tabs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid; */
    padding-bottom: 10px;
  }
  .tab-name {
    margin: 0;
    font-weight: 900;
  }
  .tab-switch-btn {
    border: none;
    background: none;
    font-size: 20px;
    margin: 0;
  }
`;
