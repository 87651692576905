import React, { useState } from "react";
import styled from "styled-components";
import { BiMenu } from "react-icons/bi";
import { FaShoppingBasket } from "react-icons/fa";
import logo from "../media/logo.PNG";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Header = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <HeaderContainer>
      <div className="header-elements">
        <p
          className="menu-btn"
          onClick={() => {
            setIsMenuOpen((prev) => !prev);
          }}
        >
          <BiMenu />
        </p>
        {/* <img src={logo} alt="" className="logo" /> */}
        <p
          className="logo"
          onClick={() => {
            navigate("/products");
          }}
        >
          Classic Cakes
        </p>
        <p
          className="menu-btn"
          onClick={() => {
            navigate("/cart");
          }}
        >
          <FaShoppingBasket />
        </p>
      </div>
      {isMenuOpen && (
        <motion.div
          className="menu"
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          onClick={() => {
            setIsMenuOpen((prev) => !prev);
          }}
        >
          <a
            href=""
            className="menu-link"
            onClick={() => {
              setIsMenuOpen((prev) => !prev);
              navigate("/custom-cake");
            }}
          >
            Custom Cakes
          </a>
          <a
            href=""
            className="menu-link"
            onClick={() => {
              setIsMenuOpen((prev) => !prev);
              navigate("/products");
            }}
          >
            Shop All
          </a>
          <a href="" className="menu-link">
            My Profile
          </a>
          <a href="" className="menu-link">
            Track order
          </a>
          <a href="" className="menu-link">
            Contact
          </a>
          <a href="" className="menu-link">
            Help
          </a>
        </motion.div>
      )}
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #a3705f;
  min-height: 45px;
  position: fixed;
  top: 0;
  min-width: 100vw;
  left: 0;
  align-items: center;
  margin: 0 auto;
  z-index: 1000;

  .header-elements {
    display: flex;
    width: 90vw;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    z-index: 1001;
  }
  .menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: black;
    top: 0;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .menu-link {
    text-decoration: none;
    color: #a3705f;
  }

  .menu-btn {
    margin: 0;
    font-size: 25px;
    color: white;
    cursor: pointer;
  }
  .logo {
    margin: 0;
    color: white;
    cursor: pointer;
  }
`;
