import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const FlavorPicker = ({ flavor, setFlavor, allFlavors }) => {
  return (
    <Picker>
      <p className="title">Pick your flavor</p>
      <div className="flavors-selector">
        {allFlavors.map((flavorItem) => {
          return (
            <motion.div
              initial={{ y: 200, opacity: 0, scale: 0.8 }}
              animate={{ y: 0, opacity: 1, scale: 1 }}
              transition={{ duration: 0.7 }}
              className={
                flavor.id === flavorItem.id ? "flavor selected" : "flavor"
              }
              onClick={() => setFlavor(flavorItem)}
            >
              <p className="flavor-name">{flavorItem.name}</p>
              <p className="flavor-price">{`Rs ${flavorItem.pricePerKg}/kg`}</p>
            </motion.div>
          );
        })}
      </div>
    </Picker>
  );
};

export default FlavorPicker;

const Picker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .flavors-selector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 10px;
  }

  .title {
    font-size: 20px;
    font-weight: 900;
    text-align: center;
  }
  .flavor {
    display: flex;
    width: 40vw;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
    border: 1px solid;
    cursor: pointer;
    border-radius: 7px;
  }
  .flavor-price {
    margin: 0;
  }
  .flavor-name {
    margin: 0;
  }

  .selected {
    border: 2px solid black;
    font-weight: 900;
    box-shadow: 5px 5px 20px #a18d87;
    background-color: #a3705f;
    color: #f2e6e2;
  }
`;
