import React from "react";
import ProductsList from "./components/productsList";
import DATA from "../../../_data";
import styled from "styled-components";

const AllProducts = () => {
  return (
    <Container>
      <p className="title">All Products</p>
      <ProductsList products={DATA.AllProducts} />
    </Container>
  );
};

export default AllProducts;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  gap: 20px;

  .title {
    font-size: 20px;
    font-weight: 900;
    color: #a3705f;
    margin: 0;
  }
`;
