import React from "react";
import styled from "styled-components";
import {
  AiFillMinusCircle,
  AiFillPlusCircle,
  AiFillDelete,
} from "react-icons/ai";

const CartItem = ({ item }) => {
  return (
    <ItemContainer>
      <div className="product-details">
        <img src={item.image} alt="" className="product-thumbnail" />
        <div className="details">
          <p className="product-name">{item.productName}</p>
          <div className="update-product">
            <div className="quantity-updater">
              <button className="quantity-update-btn">
                <AiFillPlusCircle />
              </button>
              <p className="quantity">{item.quantity}</p>
              <button className="quantity-update-btn">
                <AiFillMinusCircle />
              </button>
              <button className="delete-btn">
                <AiFillDelete />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="remove-and-price">
        <p className="product-price">{item.quantity * item.sellingPrice}</p>
      </div>
    </ItemContainer>
  );
};

export default CartItem;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;

  .product-details {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .remove-and-price {
    display: flex;
    flex-direction: column;
  }
  .product-thumbnail {
    display: flex;
    width: 100px;
    border-radius: 7px;
    height: 100px;
  }

  .quantity-updater {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .quantity-update-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  .delete-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 15px;
  }
`;
