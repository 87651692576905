import React from "react";
import styled from "styled-components";
import { GoStar } from "react-icons/go";

const SingleReview = ({ review }) => {
  return (
    <ReviewContainer>
      <div className="rating-container">
        {[...Array(review.rating)].map((e, i) => {
          return (
            <span className="rating" key={i}>
              <GoStar />{" "}
            </span>
          );
        })}
      </div>

      <p className="review">{review.review}</p>
      <p className="name">{review.name}</p>
    </ReviewContainer>
  );
};

export default SingleReview;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid;
  padding: 10px;
  border-radius: 7px;

  .name {
    margin: 0;
    font-weight: 600;
  }
  .review {
    font-size: 15px;
    margin: 0;
  }

  .rating {
    color: #a3705f;
  }
`;
