import React, { useState } from "react";
import styled from "styled-components";
import CakeCreator from "./components/cakeCreator";

const CustomizedCake = () => {
  const [isCreating, setIsCreating] = useState(true);
  return (
    <CakeContainer>
      <CakeCreator setIsCreating={setIsCreating} isCreating={isCreating} />
      {!isCreating && <button className="checkout">Add to cart</button>}
    </CakeContainer>
  );
};

export default CustomizedCake;

const CakeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  gap: 20px;

  .checkout {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    border: none;
    background: none;
    font-family: "Inter";
    background-color: #a3705f;
    border-radius: 7px;
    height: 40px;
    color: #f2e6e2;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
`;
