import React, { useState } from "react";
import styled from "styled-components";
import SelectedCake from "../../../../media/size-selected.png";
import UnselectedCake from "../../../../media/size-unselected.png";
import { FaShoppingBasket } from "react-icons/fa";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const ProductCard = ({ product }) => {
  const [size, setSize] = useState(1);
  const [image, setImage] = useState(0);

  const movePrevImage = () => {
    if (image > 0) {
      setImage((prev) => prev - 1);
    }
  };
  const moveNextImage = () => {
    if (image < product.images.length - 1) {
      setImage((prev) => prev + 1);
    }
  };

  return (
    <Card>
      <p className="product-name">{product.name}</p>
      <img src={product.images[image]} alt="" className="product-img" />
      <div className="navigate-image">
        <button className="img-nav" onClick={movePrevImage}>
          <BsArrowLeft />
        </button>
        <button className="img-nav" onClick={moveNextImage}>
          <BsArrowRight />
        </button>
      </div>
      <p className="title-small">SIZE</p>
      <div className="size-list">
        <img
          src={product.images[0]}
          alt=""
          style={{
            width: `${100 / 6 - 4}%`,
            filter: `${size === 1 ? "none" : "grayscale(90%)"}`,
          }}
          onClick={() => {
            setSize(1);
          }}
          className={size === 1 ? "size-selector selected" : "size-selector"}
        />
        <img
          src={product.images[0]}
          alt=""
          style={{
            width: `${100 / 6 - 3}%`,
            filter: `${size === 2 ? "none" : "grayscale(90%)"}`,
          }}
          onClick={() => {
            setSize(2);
          }}
          className={size === 2 ? "size-selector selected" : "size-selector"}
        />
        <img
          src={product.images[0]}
          alt=""
          style={{
            width: `${100 / 6 - 2}%`,
            filter: `${size === 3 ? "none" : "grayscale(90%)"}`,
          }}
          onClick={() => {
            setSize(3);
          }}
          className={size === 3 ? "size-selector selected" : "size-selector"}
        />
        <img
          src={product.images[0]}
          alt=""
          style={{
            width: `${100 / 6 - 1}%`,
            filter: `${size === 4 ? "none" : "grayscale(90%)"}`,
          }}
          onClick={() => {
            setSize(4);
          }}
          className={size === 4 ? "size-selector selected" : "size-selector"}
        />
        <img
          src={product.images[0]}
          alt=""
          style={{
            width: `${100 / 6}%`,
            filter: `${size === 5 ? "none" : "grayscale(90%)"}`,
          }}
          onClick={() => {
            setSize(5);
          }}
          className={size === 5 ? "size-selector selected" : "size-selector"}
        />
      </div>
      <div className="size-texts">
        <p
          className="size"
          style={{
            width: `${100 / 6}%`,
            filter: `${size === 2 ? "none" : "grayscale(90%)"}`,
          }}
        >
          5 Kg
        </p>
        <p
          className="size"
          style={{
            width: `${100 / 6 - 1}%`,
            filter: `${size === 2 ? "none" : "grayscale(90%)"}`,
          }}
        >
          4 Kg
        </p>
        <p
          className="size"
          style={{
            width: `${100 / 6 - 2}%`,
            filter: `${size === 2 ? "none" : "grayscale(90%)"}`,
          }}
        >
          3 Kg
        </p>
        <p
          className="size"
          style={{
            width: `${100 / 6 - 3}%`,
            filter: `${size === 2 ? "none" : "grayscale(90%)"}`,
          }}
        >
          2 Kg
        </p>
        <p
          className="size"
          style={{
            width: `${100 / 6 - 4}%`,
            filter: `${size === 2 ? "none" : "grayscale(90%)"}`,
          }}
        >
          1 Kg
        </p>
      </div>
      <p className="title-small product-price">{`Rs ${
        product.pricePerKg * size
      }`}</p>

      <button className="add-to-cart">
        Add to basket <FaShoppingBasket />
      </button>
    </Card>
  );
};

export default ProductCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  gap: 10px;

  .size-texts {
    display: flex;
    justify-content: space-between;
  }

  .size {
    text-align: center;
    margin: 0;
    font-size: 14px;
  }

  .product-img {
    /* width: 80%;
    margin: 0 auto; */
    /* border: 2px solid black; */
    padding: 5px;
    border-radius: 10px;
  }
  .navigate-image {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }

  .img-nav {
    border: none;
    background: none;
    font-weight: 900;
    font-size: 20px;
    cursor: pointer;
  }
  .images-list {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .img-thumbnail {
    border-radius: 10px;
    padding: 5px;
  }
  .title-small {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 900;
    color: #a3705f;
    margin: 10px 0;
  }
  .size-selector {
    height: 100%;
    margin-top: auto;
  }

  .size-list {
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
    justify-content: space-between;
  }
  .product-price {
    margin: 20px 0;
  }
  .add-to-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    border: none;
    background: none;
    font-family: "Inter";
    background-color: #a3705f;
    border-radius: 7px;
    height: 40px;
    color: #f2e6e2;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
  .product-name {
    font-size: 25px;
    margin: 10px 0;
    text-align: center;
    font-weight: 900;
  }
`;
