import React from "react";
import styled from "styled-components";
import SingleReview from "./singleReview";

const Reviews = ({ reviews }) => {
  return (
    <ReviewContainer>
      {reviews.map((review) => {
        return <SingleReview review={review} key={review.id} />;
      })}
    </ReviewContainer>
  );
};

export default Reviews;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
